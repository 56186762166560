document.addEventListener('DOMContentLoaded', function() {
  const header = document.querySelector('header');
  const subMenus = header.querySelectorAll('.sub-menu');

  subMenus.forEach(function(subMenu) {
    const postItem = subMenu.querySelector('.post-item');

    if (postItem) {
      subMenu.style.setProperty('--tw-translate-x', '-300px');
      subMenu.appendChild(postItem);
    }
  });
});

let ticking = false;

document.addEventListener('scroll', function() {
  if (!ticking) {
    window.requestAnimationFrame(function() {
      const gElement = document.getElementById('scroll-hide');
      if (gElement) {
        const paths = gElement.querySelectorAll('path[fill="#07F968"]');
        if (window.scrollY > 30) { // Adjust the scroll value as needed
          paths.forEach(path => path.classList.add('hidden'));
        } else {
          paths.forEach(path => path.classList.remove('hidden'));
        }
      }
      ticking = false;
    });
    ticking = true;
  }
});